import * as React from 'react'
import { useState } from 'react'
import { FunctionComponent } from 'react'
import { DialogActions } from '@material-ui/core'
import { FormInput } from '../components/sharedStyles'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Mutation from 'react-apollo/Mutation'
import { navigate } from 'gatsby'
import { setLocalToken } from '../utils/client'
import { SIGN_UP_USER } from '../apollo/mutations'

interface WelcomeProps {
	name: string
}

const ErrorSnackbar = styled(Snackbar)`
	& > div {
		background-color: darkred;
	}
`

const CreateAccount: FunctionComponent<WelcomeProps> = props => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	return (
		<>
			<Mutation
				mutation={SIGN_UP_USER}
				update={(
					cache,
					{
						data: {
							signup: { token }
						}
					}
				) => {
					setLocalToken(token)
				}}
				onCompleted={data => {
					const {
						signup: { token }
					} = data
					if (token !== '') {
						navigate('/jobs')
					}
				}}
			>
				{(SignUpUser, { loading, error, data }) => (
					<div>
						<form
							onSubmit={e => {
								e.preventDefault()
								SignUpUser({
									variables: {
										firstName,
										lastName,
										email,
										password
									}
								})
							}}
						>
							<FormInput
								error={error !== undefined}
								id="firstName"
								label={'First Name'}
								onChange={e => setFirstName(e.target.value)}
								margin="normal"
								value={firstName}
							/>
							<FormInput
								error={error !== undefined}
								id="lastName"
								label={'Last Name'}
								onChange={e => setLastName(e.target.value)}
								margin="normal"
								value={lastName}
							/>
							<FormInput
								error={error !== undefined}
								id="email"
								label={'email'}
								onChange={e => setEmail(e.target.value)}
								margin="normal"
								value={email}
							/>
							<FormInput
								error={error !== undefined}
								id="password"
								label={'password'}
								type="password"
								onChange={e => setPassword(e.target.value)}
								margin="normal"
								value={password}
							/>
							<DialogActions>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									<Button
										disabled={loading}
										type="submit"
									>
										Login
									</Button>
								)}
							</DialogActions>
							<ErrorSnackbar
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left'
								}}
								open={error !== undefined}
								autoHideDuration={3000}
								onClose={() => null}
								ContentProps={{
									'aria-describedby': 'message-id'
								}}
								message={
									<span id="message-id">
										Error creating account. Please retry.
									</span>
								}
							/>
						</form>
					</div>
				)}
			</Mutation>
		</>
	)
}

export default CreateAccount

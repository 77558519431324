import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import {
  FormControl,
} from '@material-ui/core';

// eslint-disable-next-line
export const StyledDrawer = styled(Drawer)`
  & > div {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const FormInput = styled(TextField)`
  width: 100%;
`;

export const StyledFormControlForSelect = styled(FormControl)`
	margin-right: 12px;
	min-width: 84px;
	width: 250px;
	margin-top: 12px;
	&& > div > div[class*="control"] {
	  min-height: 51px;
	}
	&& > div > div[class*="menu"] {
	  z-index: 2;
	}
`;